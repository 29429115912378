import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

import mta1 from "../images/mta1.png";
import mta2 from "../images/mta2.png";
import mta3 from "../images/mta3.png";
import mta4 from "../images/mta4.png";
import mta5 from "../images/mta5.png";


const PortfolioPage = () => (
  <Layout>
    <SEO 
      title="Javier Suzuki | Design & Front-end Development Portfolio - Marketing Campaigns & Landing Pages Development"
      keywords={[`javier`, `suzuki`, `web`, `ui`, `ux`, `design`, `front-end`, `development`, `marketing campaign`, `landing page`]}
    />
    <div className="portfolio-header">
      <h1>Modern Teaching Aids</h1>
      <h5>Marketing Campaigns & Landing Pages</h5>
      <p>Front-end development of marketing campaigns and landing pages, working collaboratively with graphic designers and marketing specialists.</p>
    </div>

    <div className="portfolio-content">
      <figure className="portfolio-sample">
        {/* <figcaption className="figheading">Marketing Campaign Pages</figcaption> */}
        <img src={mta1} alt=""/>
      </figure>
      <figure className="portfolio-sample">
        {/* <figcaption className="figheading">Design & Development of Marketing Blog</figcaption> */}
        <img src={mta2} alt=""/>
      </figure>
      <figure className="portfolio-sample">
        {/* <figcaption className="figheading">Design & Development of Marketing Blog</figcaption> */}
        <img src={mta3} alt=""/>
      </figure>
      <figure className="portfolio-sample">
        {/* <figcaption className="figheading">Design & Development of Marketing Blog</figcaption> */}
        <img src={mta4} alt=""/>
      </figure>
      <figure className="portfolio-sample">
        {/* <figcaption className="figheading">Design & Development of Marketing Blog</figcaption> */}
        <img src={mta5} alt=""/>
      </figure>
    </div>

    <div className="portfolio-footer">
      {/* <a className="btn btn-primary" href="#">View Project</a> */}
    </div>

  </Layout>
)

export default PortfolioPage
